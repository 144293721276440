<template>
  <div class="page" @click="shutSwiper">
    <div class="big-swiper" v-if="swiperActive">
      <swiper :options="swiperOption" style="overflow: hidden">
        <swiper-slide v-for="(item,index) in swiperList" :key="index">
<!--          <img :src="item" />-->
          <div class="swiper-img" :style="{ 'background-image': 'url(' + item + ')' }"></div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="bg1">
      <div class="bg-img"></div>
    </div>
    <div class="page-view">
      <div class="top">
        <div class="date-box">
          <div class="box-img">
            <img src="../static/index/t25.png" />
          </div>
          <span>日期：{{ today }}</span>
        </div>
        <div class="date-box" @click="refresh()">
          <div class="box-img">
            <img src="../static/index/t7.png" />
          </div>
          <span>{{ areaName }} {{ weather.temperature }}℃ 风向 {{ weather.winddirection }}</span>
        </div>
      </div>
      <div class="header-box">
        <div class="header-box-content">
          <swiper :options="swiperOption" style="overflow: hidden">
            <swiper-slide v-for="(item,index) in videoList" :key="index">
<!--              <video class="swiper-video" :src="item"  autoplay preload loop muted :controls="false" @ended="endPlay(index)"></video>-->
                <video
                        ref="videoPlayer"
                        class="swiper-video"
                        controls
                        autoplay
                        preload="auto"
                        loop="loop"
                        @loadedmetadata="cacheVideo"
                >
                    <source :src="item" type="video/mp4">
                </video>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="nav-box">
        <div class="nav-list" v-for="(item, index) in menus" :key="index" @click="checkMenu(index)">
          <div class="icon">
            <img :src="item.icon" />
          </div>
          <span :class="menuIndex === index ?'active':''">{{ item.name }}</span>
        </div>
      </div>
      <div class="mini-nav-box" v-if="menus[menuIndex].type === 1">
        <div v-for="(item,index) in menus[menuIndex].contentList" :key="index" :class="navIndex === index?'mini-nav-box-list active':'mini-nav-box-list'">
          <div @click="checkNav(index)">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="content" v-if="menus[menuIndex].type === 1">
        <div class="content-title">
          {{ menus[menuIndex].contentList[navIndex].title }}
        </div>
        <div class="content-text">
          <Editor
              v-model="menus[menuIndex].contentList[navIndex].content"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
          />
        </div>
      </div>
      <div class="content1" v-if="menus[menuIndex].type === 2">
        <div class="content1-list" v-if="!show">
          <div class="content1-list-title" v-for="(item,index) in menus[menuIndex].contentList" :key="index" @click="checkNav1(index)">
            <div>{{ item.title }}</div>
            <img src="../static/index/right.png" />
          </div>
        </div>
        <div class="content1-text" v-if="show">
          <Editor
              v-model="menus[menuIndex].contentList[navIndex].content"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
          />
        </div>
      </div>
      <div class="content1" v-if="menus[menuIndex].type === 3">
        <div class="content2-text">
          <div class="table-head">
            <div class="table-body-tr" style="width:30%;text-align: center;">商户照片</div>
            <div class="table-body-tr" style="width:20%;text-align: center;">经营户姓名</div>
            <div class="table-body-tr" style="width:20%;text-align: center;">摊位号</div>
            <div class="table-body-tr" style="width:30%;text-align: center;">评价</div>
          </div>
          <div class="table-body">
            <div class="table-body-td" v-for="(item, index) in menus[menuIndex].merchantList" :key="index">
              <div class="table-body-tr" style="width:30%;text-align: center;">
                <img :src="item.pic" mode="aspectFill" />
              </div>
              <div class="table-body-tr" style="width:20%;text-align: center;">{{ item.name }}</div>
              <div class="table-body-tr" style="width:20%;text-align: center;">{{ item.sn }}</div>
              <div class="table-body-tr" style="width:30%;text-align: center;">{{ item.comment }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Editor } from '@wangeditor/editor-for-vue'
import { getWeather, getDetail, getVersion } from "@/api/common"

export default {
  name: 'dbIndex',
  watch: {
  },
  components: { Editor },
  data() {
    return {
      editor: null,
      editorConfig: {
        placeholder: '请输入内容...',
        readOnly: true
      },
      mode: 'default',
      swiperOption: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        initialSlide: 1,
        slidesPerGroup: 1,
        loop: true,
        onSlideChangeEnd: swiper => {
          console.log('onSlideChangeEnd', swiper.realIndex)
        }
      },
      swiperVideoOption: {
        autoplay: {
          delay: 60000 * 5, // 5分钟
          disableOnInteraction: false
        },
        initialSlide: 1,
        slidesPerGroup: 1,
        loop: true,
        onSlideChangeEnd: swiper => {
          console.log('onSlideChangeEnd', swiper.realIndex)
        }
      },
      swiperList: [],
      videoList: [],
      menus: [
        {
          name: '市集信息',
          icon: require('@/static/index/t13.png'),
          type: 1,
          contentList: [
            {
              title: '市集简介',
              content: '<div style="text-indent: 44px;">里河市集，系苏州双塔街道资产，位于里河新村商业区内，面积3600平方米，该项目含农贸市场、沿街商铺、停车场等综合管理，其中农贸市场由苏州横街市场管理有限公司管理运营，市场运营时间：18年。</div><div style="text-indent: 44px;">里河市集是现代化智慧农贸市集，运用了多项高科技硬件和软件产品，包含：AI智能识别电子秤、双目摄像头、智能水电表、大数据驾驶舱、智能电子秤管理后台、智慧农贸市场管理系统、溯源小程序、智能交费小程序等，完整、精准的采集农贸市场大数据，自动、高效的管理农贸市场商户，数字化应用实现保供稳价，确保食品安全，助力政府“菜篮子”工程，系智慧农贸市场的示范项目。</div>'
            },

          ]
        },
        {
          name: '商户信息',
          icon: require('@/static/index/t5.png'),
          type: 3,
          merchantList: []
        },
        {
          name: '管理制度',
          icon: require('@/static/index/t10.png'),
          type: 2,
          contentList: []
        },
        {
          name: '科普服务',
          icon: require('@/static/index/t15.png'),
          type: 1,
          contentList: [
            {
              title: '生活窍门',
              text: ''
            },
            {
              title: '饮食健康',
              text: ''
            }
          ]
        },
        {
          name:'重要通知',
          icon:require('@/static/index/t8.png'),
          type:2,
          contentList: []
        }
      ],
      weather: {},
      menuIndex: 0,
      navIndex: 0,
      content: {},
      navList: [],
      today: '',
      contentTitle: '',
      contentText: '',
      videoIndex: 0,
      swiperActive: false,
      swiperTime: 0,
      nDate: '',
      marketId: '',
      version: '',
      show: false,
      areaName: ''
    }
  },
  created () {
    // 1604848371201314819
    const _this = this
    _this.marketId = _this.$route.params.marketId
    setInterval(function () {
      // 每5分钟查询一次版本
      _this.getVersion()
    }, 1000 * 60 * 5)
    setInterval(()=>{
      _this.swiperTime ++
      if(_this.swiperTime === 30){
        _this.swiperActive = true
      }
    },1000)
    this.getVersion()
    this.getWeather()
    this.fnCreatClock()
  },
  mounted() {
  },
  methods: {
    cacheVideo () {
        const video = this.$refs.videoPlayer
        const src = video.currentSrc
        const cache = window.caches.open('video-cache')
        cache.then(cache => { cache.add(src)})
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    refresh(){
      window.location.reload()
    },
    fnCreatClock(){
      const that = this
      //声明时间相关变量
      var dLocal, nYear, nMonth, nDate, nHours, nMinutes, nSeconds

      //1 获取计算机本地时间
      function fnGetDate () {
        //1.1 调用date对象
        dLocal = new Date()
        //1.2 获取当前年份
        nYear = dLocal.getFullYear()
        //1.3 获取当前月份，月份是从0开始计数，所以需要加1才是正确的月份
        nMonth = dLocal.getMonth() + 1
        //1.4 获取当前日期
        nDate = dLocal.getDate()
        that.nDate = nDate
        //1.5 获取当前小时
        nHours = dLocal.getHours()
        //1.6 获取分钟
        nMinutes = dLocal.getMinutes()
        //1.7 获取秒数
        nSeconds = dLocal.getSeconds()
        if (that.nDate !== nDate) {
          that.getWeather()
        }
      }

      //2.1 封装一个函数，用于把单数字前添加字符串0，例如1改为01
      function fnToDouble (num) {
        //声明一个返回结果
        let sResult = ''
        if (num < 10) {
          //判断数字小于10则是单数字，需要在前面添加字符串0
          sResult = '0' + num
        } else {
          //数字为10以上转换为字符串
          sResult = '' + num
        }
        //返回格式化后的字符串
        return sResult
      }

      function fnFormatDate () {
        //2.2 组合时间数据为字符串。本实例主要针对初学者，所以这里用的是最简单的格式化方式，即把所有数据用+号相连
        return nYear + '-' + fnToDouble(nMonth) + '-' + fnToDouble(nDate) +
            ' ' + fnToDouble(nHours) + ':' + fnToDouble(nMinutes) + ':' + fnToDouble(nSeconds)
      }


      //获取时间
      fnGetDate ()
      //3.2 修改clock元素中的时间
      this.today = fnFormatDate()

      //使用定时器实时更新时间

      setInterval (function() {
        //3.3 每秒更新时间
        fnGetDate()
        //3.3 修改clock元素中的时间
        that.today = fnFormatDate()
      },1000)
    },
    getWeather () {
      getWeather(this.marketId).then(res => {
        if (!res.success) {
          return
        }
        this.areaName = res.data.areaName
        this.weather = res.data.lives[0]
      })

    },
    getVersion () {
      getVersion(this.marketId).then(res => {
        if (!res.success) {
          return
        }
        if (res.data === this.version) {
          // 版本号一致 不更新数据
        } else {
          // 同步版本更新
          this.version = res.data
          this.loadData()
        }
      })
    },
    loadData () {
      getDetail(this.marketId).then(res => {
        if (!res.success) {
          return
        }
        const result = res.data
        if (result.slideImg && result.slideImg.length > 0) {
          // String 转成数组
          this.swiperList = result.slideImg.split(',')
        }
        if (result.video && result.video.length > 0) {
          this.videoList = result.video.split(',')
        }
        this.menus = res.data.guideCategoryList
        this.navList = this.menus[0].children
      })
    },
    shutSwiper () {
      this.swiperTime = 0
      this.swiperActive = false
    },
    endPlay (index) {
      this.videoIndex = index + 1
      if (this.videoList.length < index) {
        this.videoIndex = 0
      }
    },
    checkMenu (index) {
      this.menuIndex = index
      this.navIndex = 0
      this.show = false
    },
    checkNav (index) {
      this.navIndex = index
    },
    checkNav1 (index) {
      this.navIndex = index
      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/common';
/deep/ .w-e-text-container {
  text-align: left;
  font-size: 20px;
  line-height: 32px;
}
/deep/ .w-e-image-container {
  img {
    width: 570px;
    height: auto;
  }
}
</style>

